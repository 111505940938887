@tailwind base
@layer base
    .gift-code-filter
        @apply w-screen h-screen absolute top-0 left-0 bg-gray-700 bg-opacity-70 flex justify-end cursor-pointer animate-smooth-in

        & > .content
            @apply bg-white h-full py-2 w-full md:w-1/5 md:max-w-sm flex flex-col cursor-default  animate-right-slide-in

            & > .header
                @apply flex justify-between my-2 px-2

                & > h2
                    @apply text-xl font-semibold

            & > .option
                @apply w-full px-2 h-8 flex justify-between items-center

                &:nth-child(2n+1)
                    @apply bg-gray-lightest
