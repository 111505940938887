@tailwind base
@layer base
    .delete-account
        @apply h-full w-full flex flex-col pl-4

        & > h3
            @apply text-3xl font-bold mb-4

        & > p
            @apply text-xl text-gray-500 mb-4
