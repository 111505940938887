@mixin nav-link($theme-name, $primary-color)
    .#{$theme-name}
        .NavLink
            .icon
                padding: 7.5px
                margin: 0 12px
                @apply shrink-0 text-#{$primary-color} bg-white rounded-xl h-8 w-8

            &:hover .icon, &.active .icon
                @apply text-white bg-#{$primary-color}

@tailwind base
@layer base
    @include nav-link('parent', 'teal-300')
    @include nav-link('professional', 'purple-600')
