@tailwind base
@layer base

    .gift-code
        @apply w-full h-full flex items-center justify-center relative

        & > .content
            @apply w-full max-w-sm h-full max-h-64 shadow-lg rounded-xl flex items-center justify-center bg-white z-10 relative

            & > .loading
                @apply w-full h-full absolute left-0 top-0 bg-gray-700 opacity-60 rounded-xl items-center justify-center flex

            .icon
                @apply  w-14 h-14 p-2 rounded-full text-white

            & > div
                @apply w-full h-full text-center flex flex-col items-center justify-center px-8 gap-4 text-gray-600

                &.success
                    & > .icon
                        @apply bg-green-500

                        & > path
                            stroke-width: 50

                &.failed
                    & > .icon
                        @apply bg-red-600

                & > p
                    &:first-of-type
                        @apply font-semibold text-gray-700

                    & > a
                        @apply text-teal-300 opacity-80 transition

                        &:hover
                            @apply opacity-100
