@tailwind base
@layer base
    .register-container
        @apply flex items-center justify-between w-screen h-screen overflow-auto lg:h-screen bg-gray-lightest

        & > div:first-child
            @apply flex items-start justify-center h-full w-full lg:w-1/2 p-1 overflow-auto

        &> div:last-child
            @apply hidden h-full w-1/2 lg:flex
