@tailwind base
@layer base
    .reset-password
        @apply flex flex-row items-center justify-center w-screen h-screen bg-white
        & > .background
            @apply flex w-screen h-96 absolute top-0 left-0 p-4 z-0

            & > div
                background-position: center

                @apply flex items-center justify-center w-full h-full  rounded-3xl z-0 text-white pt-10 bg-teal-300 bg-repeat-y bg-cover

        & > .content
            @apply flex flex-col items-center justify-center h-5/6 md:h-4/6 w-full md:max-w-md z-10 bg-white rounded-3xl shadow-xl p-5 relative overflow-hidden gap-4

            & > img:first-child
                @apply h-20

            & > form
                @apply w-4/5 flex flex-col my-4

                & > button
                    @apply w-full h-12 bg-teal-300 text-white rounded-lg

            & > .result
                @apply flex flex-col py-4 items-center gap-4

                & > a,
                & > button
                    @apply flex items-center justify-center w-full h-12 rounded-lg bg-teal-300 text-white
