@tailwind base
@layer base
    .login
        button
            &:hover
                @apply filter brightness-90 transition
        @apply flex flex-col items-center justify-center px-5 py-10 w-full h-full rounded-3xl shadow-xl z-10 bg-white relative overflow-hidden

        .loading
            @apply flex items-center justify-center absolute w-full h-full bg-gray-800 opacity-25

        form
            @apply w-4/5

            .form-functions
                @apply flex flex-col items-center justify-evenly h-36 w-full
                button:nth-child(2)
                    @apply w-full h-9 rounded-xl transition ring-2 ring-teal-300
