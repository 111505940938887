@mixin inputs($theme-name, $primary-color)
    .#{$theme-name}

        .password-input
            .eye
                top: 50%
                @apply absolute right-5 h-6 w-8

                & > svg
                    @apply w-full h-full

        .input-block
            @apply flex items-center gap-1

            label
                @apply cursor-pointer

            input
                &[type="radio"]
                    @apply appearance-none relative w-4 h-4 rounded-full border-2 transition cursor-pointer

                    &:checked
                        @apply border-#{$primary-color}

                        &::after
                            content: ""
                            @apply flex top-0.5 left-0.5 w-2 h-2 rounded-full bg-#{$primary-color} absolute
                &:disabled
                    @apply bg-gray-300

        .file
            @apply relative flex flex-col items-center justify-center gap-2 px-2 w-36 h-32 border-none bg-white shadow-xl rounded-lg cursor-pointer

            .delete-file
                top: -12px
                right: -12px
                @apply absolute text-white w-6 h-6 bg-red-500 p-1 rounded-full z-10

            p
                @apply w-full overflow-hidden text-ellipsis whitespace-nowrap

            .icon
                @apply w-10 h-10 p-2 rounded-full

        .file-slim
            @apply relative flex items-center gap-2 px-2 w-full h-14 shadow-sm bg-white border-2 border-solid border-gray-300 rounded-xl cursor-pointer text-gray-700

            .delete-file
                @apply text-white w-6 h-6 bg-red-500 p-1 rounded-full z-10 filter hover:brightness-110 transition

            p
                @apply w-full overflow-hidden text-ellipsis whitespace-nowrap

            .icon
                @apply w-14 h-14 p-4 rounded-full

        .file-input
            @apply relative flex flex-col items-center justify-center gap-2 w-56 h-32 border-dashed border-gray-500 border-2 rounded-lg

            input
                @apply absolute w-full h-full opacity-0 cursor-pointer

            .icon
                @apply w-10 h-10 p-2 rounded-full bg-white shadow-lg

        .file-slim-input
            @apply relative flex items-center justify-center gap-2 w-full h-14 border-dashed border-gray-500 border-2 rounded-lg

            input
                @apply absolute w-full h-full opacity-0 cursor-pointer

            .icon
                @apply w-10 h-10 p-2 rounded-full bg-white shadow-lg

@tailwind base
@layer base
    @include inputs('professional', 'purple-600')
    @include inputs('parent', 'teal-300')
