@mixin register($theme-name, $primary-color)
    .#{$theme-name}
        .register-frame
            @apply flex flex-col items-center justify-center px-5 py-10 w-9/12 min-h-full overflow-auto lg:h-auto md:max-w-md  rounded relative

            & > .logo
                @apply h-28 w-28 p-2 bg-white rounded-full shadow-xl mb-4 flex items-center justify-center

            & > .switch
                @apply flex w-full h-12

                button
                    @apply flex font-semibold h-full w-1/2 items-center justify-center ring-2 ring-#{$primary-color} transition bg-white text-gray-700

                button:first-of-type
                    @apply rounded-l-full

                button:last-of-type
                    @apply rounded-r-full

                .active
                    @apply bg-#{$primary-color} text-white

            form
                @apply flex flex-col w-full

                div:last-child
                    @apply flex flex-col items-center justify-evenly h-28 w-full

                    button
                        @apply bg-#{$primary-color} border-none text-white text-white w-full h-12 rounded-xl transition

                        &:hover
                            filter: brightness(90%)

                    p
                        b
                            @apply text-#{$primary-color} filter transition

                            &:hover
                                filter: brightness(90%)

@tailwind base
@layer base
    @include register('parent', 'teal-300')
    @include register('professional', 'purple-600')
