@mixin invite($theme-name, $primary-color)
    .#{$theme-name}
        .invite-container
            @apply flex w-full h-24 bg-white my-2 p-2 rounded-xl shadow-md hover:shadow-lg transition-all

            & > div:first-child
                @apply flex justify-center flex-col h-full w-3/5

            & > div:last-child
                @apply flex items-center justify-end h-full w-2/5

                button
                    @apply text-#{$primary-color} border-#{$primary-color} h-10 w-10 rounded-xl bg-#{$primary-color} hover:border-white hover:text-white transition relative

                    .icon-time
                        @apply top-0 left-0 absolute opacity-100 text-white border-white h-full w-full p-2 bg-#{$primary-color} rounded-xl transition

                    .icon-close
                        @apply top-0 left-0 absolute opacity-0 pointer-events-none border-#{$primary-color} h-full w-full p-2 rounded-xl bg-red-600 border-white text-white transition

                    .icon-check
                        @apply top-0 left-0 absolute opacity-100 text-white border-white h-full w-full p-2 rounded-xl transition

                    &:hover
                        .icon-close
                            @apply opacity-100 pointer-events-auto

                        .icon-time
                            @apply opacity-0

                        .icon-check
                            @apply bg-white border-#{$primary-color} text-#{$primary-color}

@tailwind base
@layer base
    @include invite('parent', 'teal-300')
    @include invite('professional', 'purple-600')
