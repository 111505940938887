@tailwind base
@layer base
    .activity-card
        @apply h-24 w-full shadow-lg rounded-lg p-4 my-2 grid grid-cols-2 grid-rows-2 text-gray-700 transition-all overflow-hidden shrink-0

        h3
            @apply flex w-full h-full p-0 m-0 items-end col-start-1 col-span-1 row-start-1 row-span-1

        p:nth-child(2)
            @apply flex w-full h-full p-0 m-0 col-start-1 col-span-1 row-start-2 row-span-1 text-gray-500

        p:nth-child(3)
            @apply flex w-full h-full px-4 m-0 items-center justify-end col-start-2 col-span-1 row-start-1 row-span-2 font-bold

    .income
        @apply relative

        &::after
            content: " "
            @apply absolute w-full h-full bg-gradient-to-tl from-green-300 via-transparent to-transparent opacity-50 transition-opacity duration-700

        &:hover::after
            @apply opacity-60

        p:nth-child(3)
            @apply text-green-500
            &::before
                content: "+ "
                @apply font-bold text-green-500

    .outcome
        @apply relative

        &::after
            content: " "
            @apply absolute w-full h-full bg-gradient-to-tl from-red-500 via-transparent to-transparent opacity-50 transition-opacity duration-700

        &:hover::after
            @apply opacity-60

        p:nth-child(3)
            @apply text-red-700
            &::before
                content: "- "
                @apply font-bold text-red-700
