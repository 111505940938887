@tailwind base
@layer base
    .recover
        button
            &:hover
                @apply filter brightness-90 transition
        @apply flex flex-col items-center justify-center px-5 py-10 w-full h-full rounded-3xl shadow-xl z-10 bg-white relative overflow-hidden

        form
            @apply w-full

            .form-functions
                @apply flex flex-col items-center justify-evenly h-36 w-full
                button:nth-child(2)
                    @apply w-full h-9 rounded-xl transition ring-2 ring-teal-300

        .loading
            @apply w-full h-full bg-gray-700 opacity-60 flex items-center justify-center absolute top-0 left-0
