@mixin user-card($theme-name, $primary-color)
    .#{$theme-name}
        .user-card
            @apply flex items-end justify-center w-64 h-64 bg-#{$primary-color} shadow-lg rounded-lg cursor-pointer shrink-0 overflow-hidden transition

            &.disabled
                @apply opacity-60

            & > div
                @apply flex flex-col w-full h-4/5 bottom-0 rounded-t-3xl bg-white relative justify-end

                & > div:last-child
                    @apply flex flex-col h-4/5 w-full px-4 justify-evenly

                .label
                    @apply absolute bottom-5 right-5

                .profile-pic
                    top: -40px
                    left: calc(50% - 40px)

                    @apply flex items-center justify-center absolute w-20 h-20 rounded-full border-4 bg-white border-#{$primary-color} overflow-hidden

                    .icon-person
                        @apply h-4/6 w-4/6 text-#{$primary-color}

                    .profile-icon
                        @apply top-0 left-0 h-full w-full absolute text-#{$primary-color}

                a
                    @apply text-#{$primary-color}

                    &:hover
                        @apply underline

        .small-user-card
            @apply flex w-full h-24 items-center bg-white my-2 p-2 rounded-xl shadow-md hover:shadow-lg transition-all cursor-pointer gap-2

            & > div
                @apply flex flex-col justify-center p-2

                &:first-child
                    @apply w-16 items-start justify-center

                    .img
                        @apply w-14 h-14 rounded-lg  text-white bg-#{$primary-color} flex items-center justify-center overflow-hidden p-1
                        & > .icon-person
                            @apply w-full h-full rounded-lg

                &:nth-child(2)
                    @apply w-4/5 overflow-hidden

                    & > p
                        @apply text-ellipsis whitespace-nowrap overflow-hidden text-gray-500 text-sm

                        &:first-child
                            @apply text-gray-700 font-semibold text-base

                        & > a
                            @apply text-#{$primary-color} cursor-pointer
                            &:hover
                                @apply underline

                        & > span
                            @apply text-gray-500

@tailwind base
@layer base
    @include user-card('parent', 'teal-300')
    @include user-card('professional', 'purple-600')
