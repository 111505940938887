@tailwind base
@layer base
    .gift-code-info-container
        @apply w-full h-full flex flex-col items-center gap-4 p-4

        h3
            @apply text-2xl font-bold

        .gift-info
            @apply w-full flex flex-col

            & > p
                & > span
                    @apply text-xs text-gray-500
