@mixin change-password($theme-name, $primary-color)
    .#{$theme-name}
        .settings-account
            @apply flex w-full pl-4

            .auth
                @apply w-full

                & > h3
                    @apply text-3xl font-bold mb-4

                & > .prop-block
                    @apply flex flex-col justify-evenly h-24 py-2 text-gray-500

                    & > h4
                        @apply text-lg font-semibold

                & > button
                    @apply flex items-center justify-center w-40 h-12 text-white rounded-lg bg-#{$primary-color}

                    & > div
                        @apply w-10 h-10

@tailwind base
@layer base
    @include change-password('parent', 'teal-300')
    @include change-password('professional', 'purple-600')
