@mixin slider($theme-name, $primary-color)
    .#{$theme-name}
        .switch-button
            @apply flex w-10 gap-2 h-6 items-center cursor-pointer transition

            .switch
                @apply relative inline-block w-full h-full shrink-0

                input
                    @apply hidden
                    &:checked + .slider
                        @apply bg-#{$primary-color}

                        &::before
                            @apply transform translate-x-4

                .slider
                    @apply absolute flex items-center w-full h-full p-1 bg-gray-300 transition duration-300 rounded-full

                    &::before
                        content: " "
                        @apply rounded-full h-4 w-4 bg-white transition left-0

            &.active
                @apply text-gray-700

        .button
            @apply w-full h-12 rounded-xl transition flex items-center justify-center overflow-hidden relative

            &:hover
                @apply filter hover:brightness-90

            &.primary
                @apply text-white bg-#{$primary-color}

                &:disabled
                    @apply bg-gray-400 cursor-not-allowed

            &.secondary
                @apply text-gray-700  bg-white border-2 border-#{$primary-color}

            &.delete
                @apply bg-white text-red-600 border-red-600 border-2

            &.disable
                @apply opacity-20 cursor-not-allowed

        .three-switch
            grid-template-columns: 1fr 2fr 1fr
            grid-template-rows: 1fr
            @apply grid min-w-[10rem] px-1 h-10 rounded-full shadow-inner text-xs relative transition bg-gray-lightest flex-shrink-0

            &.blocked
                @apply bg-red-600 text-white

            &.active
                @apply bg-#{$primary-color} text-white

            & > button
                @apply z-10 flex items-center justify-center

                &.disabled
                    @apply cursor-default

                &.active
                    @apply text-black

            & > span
                @apply top-1 h-8 w-full rounded-full shadow bg-white absolute transition-all

                &.blocked
                    grid-column-start: 1
                    grid-column-end: 1

                &.null
                    grid-column-start: 2
                    grid-column-end: 3

                &.active
                    grid-column-start: 3
                    grid-column-end: 3

        .buttons
            @apply fixed md:absolute left-0 w-full h-12 flex items-center justify-center gap-2 md:bottom-14

            button
                @apply h-full flex items-center justify-center w-5/12 rounded-2xl

                &:first-of-type
                    @apply border-2 border-red-400 text-red-500 transition

                    &:hover
                        @apply bg-red-400 text-white

                &:last-of-type
                    @apply bg-teal-300 text-white transition

                    &:hover
                        @apply filter brightness-90

@tailwind base
@layer base
    @include slider('parent', 'teal-300')
    @include slider('professional', 'purple-600')
