@mixin profile-header($theme-name, $primary-color)
    .#{$theme-name}
        .profile-header
            background-position: center
            background-size: cover
            @apply w-full h-64 relative rounded-lg flex flex-col items-center md:mb-14 shrink-0 bg-#{$primary-color}

            .content
                bottom: -56px
                min-height: 112px
                @apply flex flex-col items-center md:flex-row bg-white bg-opacity-80 backdrop-filter backdrop-blur-sm rounded-lg w-11/12 md:absolute gap-4 p-4 mt-4 md:mt-0 shadow-lg

                .profile-pic
                    @apply h-20 w-20 rounded-full md:rounded-lg text-white flex items-center justify-center shrink-0  relative bg-#{$primary-color}

                    .icon
                        @apply w-16 h-16 absolute rounded-full md:rounded-lg shadow-none
                    .profile-icon
                        @apply w-full h-full absolute rounded-full md:rounded-lg border-none shadow-none

                    .change-picture
                        right: -10px
                        bottom: -10px
                        @apply w-6 h-6 absolute rounded-full flex items-center justify-center bg-#{$primary-color}

                .profile-info
                    @apply flex flex-col w-full justify-center items-center text-center md:items-start md:text-left md:h-full text-gray-700

                    i
                        @apply font-light text-gray-400 not-italic mx-2

                .profile-functions
                    @apply max-w-[128px] flex justify-between items-center gap-4

                    & > button
                        @apply w-8 h-8 text-#{$primary-color} p-0 transition shrink-0

                        &.function
                            @apply border-2 rounded-md

                        &.deny
                            @apply border-red-600 text-red-600 p-0.5

                            &:hover, &.active
                                @apply bg-red-600 text-white

                        &.resend
                            @apply border-orange-500 text-orange-500 p-0.5

                            &:hover, &.active
                                @apply bg-orange-500 text-white

                        &.accept
                            @apply border-#{$primary-color} text-#{$primary-color}

                            &:hover, &.active
                                @apply bg-#{$primary-color} text-white

                        & > svg
                            @apply w-full h-full

@tailwind base
@layer base
    @include profile-header('parent', 'teal-300')
    @include profile-header('professional', 'purple-600')
