@tailwind base
@layer base
    .pending-professional
        @apply w-full h-full

        & > .title
            @apply w-full flex py-4

            & > h1
                @apply text-3xl font-bold

        & > .content
            @apply w-full h-full
