@tailwind base
@layer base
    .verify-warning-container
        @apply flex w-full h-full items-center justify-center

        & > .content
            @apply flex items-center justify-center px-8 gap-4 flex-col h-4/6 md:h-2/6 w-full md:max-w-md bg-white z-10 rounded-lg overflow-hidden shadow-lg relative

            & > p
                @apply text-center text-lg

            & > .loading
                @apply absolute flex items-center justify-center top-0 left-0 w-full h-full bg-gray-700 opacity-60
