@tailwind base
@layer base
    .admin-dashboard
        grid-template-rows: 3rem auto
        @apply grid grid-cols-2 gap-y-4 gap-x-2 w-full h-full text-gray-700

        h1
            @apply text-2xl font-semibold mb-4

        & > .users
            @apply flex flex-col w-full bg-white rounded-xl shadow-xl min-h-[128px] max-h-full p-4

            & > .content
                @apply h-full w-full overflow-y-auto

            & > p
                @apply font-bold text-sm text-gray-500 self-center justify-center
