@tailwind base
@layer base
    .admin-gift-code
        @apply w-full h-full flex flex-col

        h1
            @apply text-2xl font-semibold mb-4

        .page-functions
            @apply col-span-2 flex items-center w-full h-12  shrink-0 mb-2 gap-4

            & > form
                @apply bg-white rounded-lg flex items-center px-4 overflow-hidden shadow-lg h-full w-full

            & > .function
                @apply w-12 h-12 bg-white rounded-lg flex items-center justify-center cursor-pointer p-3

        .code-container
            @apply w-full h-full flex gap-4 flex-wrap overflow-y-auto my-4
            .gift-code
                @apply w-72 h-24 flex flex-col justify-between items-center shrink bg-white shadow-lg rounded-lg p-2

                &.used
                    @apply filter brightness-90 shadow-none

                & > .code
                    @apply text-base font-semibold py-1 flex relative

                    button
                        @apply hidden absolute right-[-20px] top-2

                & > .code-info
                    @apply w-full h-full flex gap-2 items-center justify-center
                    & > p
                        @apply text-sm text-gray-500

                & > .tags
                    @apply w-full h-20 flex gap-2 items-center justify-center overflow-x-auto
                    & > p
                        @apply text-xs text-gray-500 bg-teal-300 text-white p-2 rounded-full
                        line-height: 0.5rem

                &:hover
                    button
                        @apply flex
