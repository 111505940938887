@tailwind base
@layer base
    .validation-container
        @apply flex flex-col w-full h-full

        & > .title
            @apply w-full flex py-4

            & > h1
                @apply text-3xl font-bold

        & > .content
            @apply flex flex-wrap gap-4 w-full h-full p-2

            & > p
                @apply text-gray-500
