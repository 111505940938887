@tailwind base
@layer base
    .verify-account
        @apply flex w-screen h-screen items-center justify-center

        p
            @apply text-center text-lg

        & > .background
            background-repeat: no-repeat
            background-size: cover

        & > .frame
            @apply flex h-4/6 md:h-2/6 w-full md:max-w-md bg-white z-10 rounded-lg overflow-hidden shadow-lg

            & > div
                @apply flex flex-col items-center justify-center w-full h-full

                &.loading
                    @apply bg-gray-700 opacity-60

                &.error
                    p
                        @apply text-gray-700
                        &:first-child
                            @apply text-2xl font-bold

                        &:nth-child(2)
                            @apply text-xl font-medium

                &.success
                    p
                        @apply text-gray-700 text-2xl font-bold

                button
                    @apply w-10/12 h-12 bg-teal-300 text-white rounded-lg mt-5
