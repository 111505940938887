@tailwind base
@layer base
    .verify-container
        @apply w-full h-full max-h-screen flex text-gray-700 gap-6

        &.mobile
            @apply flex-col px-2
            .title
                @apply p-2  pr-10

            & > button.information
                @apply absolute flex items-center justify-center top-4 right-4 w-6 h-6 rounded-full shadow-xl bg-white border-purple-600 border-solid border-2

        .left-container, .right-container
            @apply h-full flex flex-col gap-2
        .left-container
            @apply w-3/4

        .right-container
            @apply flex flex-col h-full w-1/4 min-w-fit p-2 shadow-xl rounded-xl bg-white
            .title
                & > h1
                    @apply text-lg text-center

            .content
                @apply flex flex-col w-full h-full items-center
                & > .icon
                    @apply w-12 h-12
                & > p
                    @apply text-lg font-semibold text-gray-700
        .title
            & > h1
                @apply text-3xl font-bold

            & > h2
                @apply text-lg text-gray-500

        .content
            @apply h-full flex flex-col justify-between

        .warning
            @apply h-full w-full flex flex-col items-center justify-center gap-4

            & > h1
                @apply text-3xl font-bold

            & > p
                @apply text-xl text-gray-500 text-center max-w-lg
