@tailwind base
@layer base
    .create-gift-code
        @apply flex flex-col w-96 h-full p-4

        h2
            @apply text-center text-lg font-bold

        .text-after
            @apply text-xs text-gray-500

        & > button
            @apply mb-2
