@tailwind base
@layer base
    .personal-settings
        @apply flex flex-col w-full h-full md:pl-4 overflow-auto

        .data
            height: calc(100% - 80px)
            @apply w-full relative

            & > h3
                @apply text-3xl font-bold mb-4

            & > .buttons
                @apply fixed md:absolute left-0 w-full h-12 flex items-center justify-center gap-2 md:bottom-14

                button
                    @apply h-full flex items-center justify-center w-5/12 rounded-2xl

                    &:first-of-type
                        @apply border-2 border-red-400 text-red-500 transition

                        &:hover
                            @apply bg-red-400 text-white

                    &:last-of-type
                        @apply bg-teal-300 text-white transition

                        &:hover
                            @apply filter brightness-90
