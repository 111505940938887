@tailwind base
@layer base
    .game-settings
        @apply flex flex-col w-full min-h-full relative p-2 pb-12 md:pb-8

        .settings-section
            @apply ml-2

        h1
            @apply text-3xl font-bold

        h2
            @apply text-2xl font-semibold mt-4

        p
            @apply text-lg font-semibold

        ul
            @apply ml-2

        &.time-settings
            .options
                & > .option
                    @apply bg-gray-lightest items-center justify-start gap-2

                    & > input
                        @apply w-12 text-center

        .options
            @apply flex flex-col gap-4 md:gap-1

            .option
                @apply flex items-center justify-between w-full px-2

                & > p
                    @apply w-5/12

                & > input
                    @apply h-12 w-24 text-xl rounded-lg shadow-lg px-2 shrink-0

        .buttons
            button[aria-disabled="true"].disabled
                @apply cursor-not-allowed
