@tailwind base
@layer base
    .clothes
        @apply w-36 h-60 rounded-lg shadow-lg flex flex-col overflow-hidden

        .image
            @apply w-full h-36
            & > img
                @apply w-full h-full

        .info
            @apply relative h-full p-1

            & > button
                @apply absolute bottom-2 right-2

            & > p
                @apply text-base font-semibold
