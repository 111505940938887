@tailwind base
@layer base
    .settings-platform
        @apply md:pl-4 font-semibold text-gray-700 shadow-lg w-full h-full relative

        h3
            @apply font-bold text-3xl mb-4

        label
            @apply text-gray-500
