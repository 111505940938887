@mixin settings($theme-name, $primary-color)
    .#{$theme-name}
        .settings
            height: 95%
            @apply w-full text-gray-700 flex

            &.game
                @apply flex-col max-h-screen

            .menu
                @apply flex flex-col w-full h-full overflow-y-auto p-2 mb-24 md:mb-0 md:pr-4 md:max-w-xs

                h1
                    @apply font-bold text-2xl mb-6
                h2
                    @apply font-semibold text-xl mb-2 text-gray-700

                .outlet
                    @apply max-h-screen

            .header
                @apply flex h-12 w-full items-center justify-between shrink-0

                & > a
                    @apply transition  duration-500
                    &:hover
                        @apply text-#{$primary-color}

            & > .main
                @apply flex w-full h-full

            .outlet
                @apply w-full h-full overflow-y-auto

@tailwind base
@layer base
    @include settings('professional', 'purple-600')
    @include settings('parent', 'teal-300')
