@tailwind base
@layer base

    .game-settings
        @apply flex flex-col w-full min-h-full relative p-0 md:pb-8

        .settings-section
            @apply ml-2

        h1
            @apply text-3xl font-bold mb-4 md:mb-8

        h2
            @apply text-2xl font-semibold m-4

        h3
            @apply text-lg text-center font-semibold

        ul
            @apply ml-2

        .title
            @apply flex items-center justify-center w-full gap-2

        .options
            @apply flex flex-col gap-4 md:gap-1

            .title
                @apply flex items-center justify-center w-full gap-2
                h2
                    @apply m-0

            .option
                @apply flex items-center justify-between w-full p-2

                &:nth-child(odd)
                    @apply bg-white

                & > p
                    @apply w-5/12

        .clothes-options
            @apply flex gap-4 md:gap-2 flex-wrap justify-center my-4

        .buttons
            @apply fixed left-0 w-full h-12 flex items-center justify-center gap-2 bottom-20 z-10 transition

            button
                @apply h-full flex items-center justify-center w-5/12 md:w-3/12 rounded-2xl

                &:first-of-type
                    @apply border-2 border-red-400 text-red-500 transition bg-white

                    &:hover
                        @apply bg-red-400 text-white

                &:last-of-type
                    @apply bg-teal-300 text-white transition

                    &:hover
                        @apply bg-white border-2 border-teal-300 text-teal-300

    .game-settings-menu
        @apply flex w-full h-full items-start justify-center gap-x-10 gap-y-4 flex-wrap

        .menu-item
            @apply flex items-center shrink-0 justify-center flex-col text-gray-700 bg-white rounded-2xl shadow-xl w-36 h-44 transition-all

            &:hover
                @apply shadow-2xl

            & > h2
                @apply text-base

            & > .icon
                @apply w-10 h-10 text-teal-300
