@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');

::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-gray-lightest;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-gray-300;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-400;
  }


  /* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
-ms-overflow-style: none;
scrollbar-width: none; /* Firefox */
}