@tailwind base
@layer base
    .lds-ellipsis
        display: inline-block
        position: relative
        width: 80px
        height: 80px

        div
            position: absolute
            top: 33px
            width: 13px
            height: 13px
            border-radius: 50%
            background: #fff
            animation-timing-function: cubic-bezier(0, 1, 1, 0)

            &:nth-child(1)
                left: 8px
                animation: lds-ellipsis1 0.6s infinite

            &:nth-child(2)
                left: 8px
                animation: lds-ellipsis2 0.6s infinite

            &:nth-child(3)
                left: 32px
                animation: lds-ellipsis2 0.6s infinite

            &:nth-child(4)
                left: 56px
                animation: lds-ellipsis3 0.6s infinite

    @keyframes lds-ellipsis1
        0%
            transform: scale(0)

        100%
            transform: scale(1)

    @keyframes lds-ellipsis3
        0%
            transform: scale(1)

        100%
            transform: scale(0)

    @keyframes lds-ellipsis2
        0%
            transform: translate(0, 0)

        100%
            transform: translate(24px, 0)

    .lds-dual-ring
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        height: 100%

        &:after
            content: " "
            display: block
            width: 80%
            height: 80%
            border-radius: 50%
            border: 3px solid #000
            border-color: #000 transparent #000 transparent
            animation: lds-dual-ring 1.2s linear infinite

    @keyframes lds-dual-ring
        0%
            transform: rotate(0deg)

        100%
            transform: rotate(360deg)

    .lds-dual-ring-white
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        height: 100%

        &:after
            content: " "
            display: block
            width: 80%
            height: 80%
            border-radius: 50%
            border: 3px solid #FFF
            border-color: #FFF transparent #FFF transparent
            animation: lds-dual-ring 1.2s linear infinite

    @keyframes lds-dual-ring
        0%
            transform: rotate(0deg)

        100%
            transform: rotate(360deg)
