@mixin invite-professional($theme-name, $primary-color)
    .#{$theme-name}
        .invite-professional
            @apply flex flex-col w-[520px] p-2 h-auto max-h-[520px] overflow-auto

            & > h1
                @apply w-full bg-gray-lightest font-bold text-lg p-4
            .content
                @apply h-96 overflow-x-hidden p-2
                .small-card
                    @apply flex w-full h-24 items-center bg-white my-2 p-2 rounded-xl shadow-md hover:shadow-lg transition-all relative

                    &.disabled
                        &:hover
                            @apply shadow-md

                    & > div
                        @apply flex flex-col justify-center p-2

                        &:first-child
                            @apply w-1/5 items-center

                            .img
                                @apply w-14 h-14 rounded-lg bg-#{$primary-color} flex items-center justify-center overflow-hidden p-1
                                & > .icon-person
                                    @apply w-full h-full rounded-lg text-white

                        &:nth-child(2)
                            @apply w-4/5 overflow-hidden

                            & > p
                                @apply text-ellipsis whitespace-nowrap overflow-hidden text-red-500 text-xs

                                &:first-child
                                    @apply text-gray-700 font-semibold text-base

                                & > a
                                    @apply text-#{$primary-color} cursor-pointer
                                    &:hover
                                        @apply underline

@tailwind base
@layer base
    @include invite-professional('parent', 'teal-300')
    @include invite-professional('professional', 'purple-600')
