@mixin functions($theme-name, $primary-color)
    .#{$theme-name}
        .functions-container
            @apply flex flex-col items-center w-full md:w-[520px] h-full
            & > .profile
                @apply w-full h-48 flex flex-col items-center justify-center

                & > .name
                    @apply text-lg font-bold

                & > .email
                    @apply text-gray-500

                & > .profile-pic
                    @apply h-28 w-28 rounded-full border-white border-4 shadow-lg text-white flex items-center justify-center shrink-0  relative bg-#{$primary-color}

                    .icon
                        @apply w-16 h-16 absolute rounded-full shadow-none
                    .profile-icon
                        @apply w-full h-full absolute rounded-full border-none shadow-none

            & > h1
                @apply font-bold text-lg p-4

            & > .option
                @apply flex items-center justify-between shadow-lg rounded-lg w-5/6 min-h-[64px] bg-white p-4 my-2 transition-shadow flex-grow

                &.sent
                    @apply bg-green-500
                    & > p
                        @apply text-white
                    & > .icon
                        @apply text-white

                    &:hover
                        & > .icon
                            @apply text-green-500 bg-white

                & > p
                    @apply font-semibold text-gray-700 text-left
                    max-width: 70%

                & > .icon
                    @apply text-#{$primary-color} border-#{$primary-color} h-10 w-10 p-2 rounded-xl  transition flex shrink-0

                &:hover
                    @apply shadow-2xl
                    & > .icon
                        @apply bg-#{$primary-color} border-white text-white

@tailwind base
@layer base
    @include functions('parent', 'teal-300')
    @include functions('professional', 'purple-600')
