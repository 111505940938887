@tailwind base
@layer base
    .settings-section
        .header
            @apply w-full h-24 flex items-center justify-between

            .organize
                @apply w-56 h-10 flex justify-between items-center

                button
                    @apply w-1/2

                    &.active
                        @apply font-bold
