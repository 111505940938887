@mixin game-user-profile($theme-name, $primary-color)
    .#{$theme-name}
        #profile
            @apply w-full flex flex-col h-full max-h-full overflow-auto

            .profile-cards
                @apply w-full flex flex-col items-center md:items-start md:grid grid-cols-3 gap-6 my-6 md:max-h-96

                .card
                    max-height: 384px

                    @apply bg-white rounded-lg p-2 md:p-4 text-gray-400 shadow-lg w-full h-full relative

                    .header
                        @apply w-full h-10 flex

                        h2
                            @apply text-gray-700 font-bold text-xl my-2 flex w-full

                        .button
                            @apply text-teal-300

                    h2
                        @apply text-gray-700 font-bold text-xl my-2

                    h3
                        @apply font-bold uppercase my-2

                    .card-session
                        @apply flex flex-col h-5/6 max-h-80 overflow-y-auto

                    .input-block
                        @apply flex flex-row items-center gap-2 w-full font-semibold transition

                        & *
                            @apply cursor-pointer

                        &:hover
                            @apply filter brightness-90

                        input
                            &[type="radio"]
                                @apply appearance-none relative w-4 h-4 rounded-full border-2 transition

                                &:checked
                                    @apply border-#{$primary-color}

                                    &::after
                                        content: ""
                                        @apply flex top-0.5 left-0.5 w-2 h-2 rounded-full bg-#{$primary-color} absolute

@tailwind base
@layer base
    @include game-user-profile('parent', 'teal-300')
    @include game-user-profile('professional', 'purple-600')
