@tailwind base
@layer base
    .remove-file
        @apply w-full h-64 flex flex-col justify-between p-4

        p
            @apply text-xl text-gray-700

            & > a
                @apply font-semibold

        .functions
            @apply w-full h-24 gap-2 flex flex-col
