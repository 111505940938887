@mixin analytic-card($theme-name, $primary-color)
    .#{$theme-name}
        .analytic-card
            min-height: 80px
            @apply flex items-center flex-grow justify-between h-full max-h-32 bg-white p-4 rounded-2xl shadow-lg lg:w-1/4

            .icon
                @apply rounded-xl p-2 bg-#{$primary-color} text-white w-11 h-11 shrink-0

            p + p
                @apply text-gray-500 font-semibold text-xs leading-3

@tailwind base
@layer base
    @include analytic-card('parent' , 'teal-300' )
    @include analytic-card('professional' , 'purple-600')
